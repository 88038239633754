var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block" }, [
    true
      ? _c(
          "form",
          {
            staticClass: "block__form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.forgotPass.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticClass: "block__form__info" }, [
              _vm._v("Para atualizar sua senha, preencha os campos abaixo:"),
            ]),
            _c("br"),
            _c("v-textbox", {
              staticClass: "block__form__password",
              attrs: {
                disabled: _vm.disableFields,
                label: "Insira o CPF ou CNPJ",
                name: "text",
                mask: ["###.###.###-##", "##.###.###/####-##"],
                placeholder: "CPF ou CNPJ",
                required: "",
              },
              model: {
                value: _vm.document,
                callback: function ($$v) {
                  _vm.document = $$v
                },
                expression: "document",
              },
            }),
            _c(
              "div",
              { staticClass: "block__form__actions" },
              [
                _c("v-button", {
                  attrs: {
                    label: "Solicitar códigos",
                    type: "submit",
                    disabled: _vm.disableFields,
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showResetPass
      ? _c(
          "form",
          {
            staticClass: "block__form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.resetPass.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticClass: "block__form__info" }, [
              _vm._v("Preecha os campos abaixo com os códigos recebidos:"),
            ]),
            _c("br"),
            _c("v-textbox", {
              staticClass: "block__form__password",
              attrs: {
                label: "Código enviado para o telefone",
                name: "código_telefone",
                mask: "######",
                placeholder: "Código do telefone",
                required: "",
              },
              model: {
                value: _vm.phoneCode,
                callback: function ($$v) {
                  _vm.phoneCode = $$v
                },
                expression: "phoneCode",
              },
            }),
            _c("p", { staticClass: "sub-label" }, [
              _vm._v(
                "Enviado para o telefone " + _vm._s(_vm.phoneAndEmail.phone)
              ),
            ]),
            _c("v-textbox", {
              staticClass: "block__form__password",
              attrs: {
                label: "Código enviado para o e-mail",
                name: "código email",
                mask: "######",
                placeholder: "Código do e-mail",
                required: "",
              },
              model: {
                value: _vm.emailCode,
                callback: function ($$v) {
                  _vm.emailCode = $$v
                },
                expression: "emailCode",
              },
            }),
            _c("p", { staticClass: "sub-label mb-0" }, [
              _vm._v(
                "Enviado para o e-mail " + _vm._s(_vm.phoneAndEmail.email)
              ),
            ]),
            _vm.showResetPass
              ? _c(
                  "a",
                  {
                    staticClass: "block__form__actions__back my-0",
                    on: { click: _vm.close },
                  },
                  [_vm._v("\n      Não recebi meu código\n    ")]
                )
              : _vm._e(),
            _c("v-user-password", {
              attrs: { data: _vm.password, responsive: false },
            }),
            _c(
              "div",
              { staticClass: "block__form__actions" },
              [_c("v-button", { attrs: { label: "Atualizar Senha" } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }