<script>
import VButton from '@/components/Button.vue';
import VTextbox from '@/components/Textbox.vue';
import VUserPassword from '@/components/UserPassword.vue';

export default {
  components: {
    VButton,
    VTextbox,
    VUserPassword
  },
  data() {
    return {
      document: '',
      showResetPass: false,
      disableFields: false,
      emailCode: '',
      phoneCode: '',
      password: {},
      phoneAndEmail: {
        email: '',
        phone: ''
      }
    };
  },
  methods: {
    close() {
      this.showResetPass = false;
      this.disableFields = false;
      this.document = '';
      this.$emit('close');
    },
    forgotPass() {
      if (this.checkIfNumCharacterDocument()) {
        this.$store.commit('message', { type: 'error', text: 'CPF ou CNPJ inválido' });
        document.getElementsByName('text')[0].focus();
        this.$store.commit('formLoading', false);
        return false;
      }
      if (this.$store.state.formLoading) return false;
      this.$store.commit('formLoading', true);

      this.$http.post('/users/user-forgot-password', { document: this.document.replace(/\D/g, '') })
        .then(({ data }) => {
          console.log(data)
          const { error } = data;
          if (error) {
            this.$store.commit('message', { type: 'error', text: error });
          } else {
            this.showResetPass = true;
            this.phoneAndEmail.email = data.emailToCode;
            this.phoneAndEmail.phone = data.phoneNumber;
            this.disableFields = true;
          }
          this.$store.commit('formLoading', false);
        }, err => {
          console.log(err.data)
          // this.$store.commit('message', { type: 'error', text: err });
          this.$store.commit('formLoading', false);
          this.disableFields = false;
          this.showResetPass = false;
          this.emailCode = '';
          this.phoneCode = '';
          this.password = {};
        });
    },
    resetPass() {
      if (this.$store.state.formLoading) return false;
      // if (this.password.senha !== this.password.confirmaSenha) {
      //   this.$store.commit('message', { type: 'error', text: 'As senhas precisam ser iguais.' });
      //   return false;
      //   // eslint-disable-next-line
      // }
      const { senha: password, confirmaSenha } = this.password;
      if (!(!!password
        && password === confirmaSenha
        && /[\w]+/.test(password) // has one letter
        && /\d+/.test(password) // has one number
        && /(.*?[\W_].*?){2,}/.test(password) // has two special characters
        && /[a-z]+/.test(password) // has one lowercase letter
        && /[A-Z]+/.test(password) // has one capital letter
        && password.length >= 10)) {
        // this.$store.commit('message', { type: 'error', text: 'A senha deve conter no mínimo 10 caracteres, uma letra maiúscula, uma letra minúscula, um número e dois caracteres especiais.' });
        return false;
      }
      this.$store.commit('formLoading', true);
      this.$http.post('/users/user-reset-password', {
        document: this.document.replace(/\D/g, ''),
        emailCode: this.emailCode,
        phoneCode: this.phoneCode,
        newPassword: this.password.senha
      }).then(({ data }) => {
        const { error } = data;
        if (error) {
          this.disableFields = false;

          this.$store.commit('message', { type: 'error', text: error });
        } else {
          this.$store.commit('message', { type: 'success', text: 'Senha alterada com sucesso.' });
          this.close();
          this.$router.push({ name: 'signIn' })
        }
        this.$store.commit('formLoading', false);
      }, err => {
        this.$store.commit('message', { type: 'error', text: err });
        this.showResetPass = false;
        this.emailCode = '';
        this.phoneCode = '';
        this.password = {};
        this.disableFields = false;
        this.$store.commit('formLoading', false);
      });
    },
    checkIfNumCharacterDocument() {
      const documentNumber = (this.document || '').replace(/\D/g, '');
      return documentNumber.length !== 11 && documentNumber.length !== 14;
    }
  }
};
</script>

<template>
    <div class="block">
      <form @submit.prevent="forgotPass" class="block__form" v-if="true">
        <p class="block__form__info">Para atualizar sua senha, preencha os campos abaixo:</p>
        <br>
        <v-textbox
          :disabled="disableFields"
          v-model="document"
          class="block__form__password"
          label="Insira o CPF ou CNPJ"
          name="text"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          placeholder="CPF ou CNPJ"
          required/>
        <div class="block__form__actions">
          <v-button label="Solicitar códigos" class="" type="submit" :disabled="disableFields"/>
          <!-- <a @click="close" class="block__form__actions__back">
            Não, voltar
          </a> -->
        </div>
      </form>


      <form @submit.prevent="resetPass" class="block__form" v-if="showResetPass">
        <p class="block__form__info">Preecha os campos abaixo com os códigos recebidos:</p>
        <br>
        <v-textbox
          v-model="phoneCode"
          class="block__form__password"
          label="Código enviado para o telefone"
          name="código_telefone"
          mask="######"
          placeholder="Código do telefone"
          required/>
        <p class="sub-label">Enviado para o telefone {{phoneAndEmail.phone}}</p>
        <v-textbox
          v-model="emailCode"
          class="block__form__password"
          label="Código enviado para o e-mail"
          name="código email"
          mask="######"
          placeholder="Código do e-mail"
          required/>
        <p class="sub-label mb-0">Enviado para o e-mail {{phoneAndEmail.email}}</p>

        <a @click="close" class="block__form__actions__back my-0" v-if="showResetPass">
          Não recebi meu código
        </a>

        <v-user-password :data="password" :responsive="false"/>

        <div class="block__form__actions">
          <v-button label="Atualizar Senha" class=""/>
          <!-- <a @click="close" class="block__form__actions__back">
            Não, voltar
          </a> -->
        </div>
      </form>

    </div>
</template>

<style lang="postcss">
.sign-in__form {
  max-height: fit-content!important;
  overflow: scroll;
}
.sign-in__aside {
  position: -webkit-sticky!important;
  position: sticky!important;
  top: 0!important;
}
</style>
<style lang="postcss" scoped>
.block {
  padding: 1.5rem 1.5rem 0;
}
.block__form__actions__back{
  display: inline-block;
  margin: 1rem 0;
  padding: 1rem 0;
}
.sub-label {
  display: block;
  font-size: .8125rem;
  margin-top: 0.4375rem;
  margin-top: -30px;
  margin-bottom: 35px;
}
</style>
