<script>
import VButton from '@/components/Button.vue';
import VTextbox from '@/components/Textbox.vue';
import VUserPassword from '@/components/UserPassword.vue';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    VButton,
    VTextbox,
    VUserPassword,
    VueRecaptcha
  },
  data() {
    return {
      document: '',
      showResetPass: false,
      disableFields: false,
      emailCode: '',
      phoneCode: '',
      password: {},
      site_key: '',
      recaptcha: null,
      phoneAndEmail: {
        email: '',
        phone: ''
      },
      cellphoneLabel: 'Código enviado para o celular',
      emailLabel: 'Código enviado para o e-mail',
      phoneField: false,
      emailField: false
    };
  },
  methods: {
    mxVerify(res) {
      this.recaptcha = res;
    },
    close() {
      this.showResetPass = false;
      this.disableFields = false;
      this.document = '';
      this.$emit('close');
    },
    handlePhoneCodeConfirmation() {
      this.phoneField = true;
    },
    handleEmailCodeConfirmation() {
      this.emailField = true;
    },
    forgotPass() {
      if (this.checkIfNumCharacterDocument()) {
        this.$store.commit('message', {
          type: 'error',
          text: 'CPF ou CNPJ inválido'
        });
        document.getElementsByName('text')[0].focus();
        this.$store.commit('formLoading', false);
        return false;
      }
      if (this.$store.state.formLoading) return false;
      this.$store.commit('formLoading', true);
      this.disableFields = true;

      this.$http
        .post('/users/user-forgot-password', {
          document: this.document.replace(/\D/g, '')
        })
        .then(
          ({ data }) => {
            this.showResetPass = true;
            this.phoneAndEmail.email = data.emailToCode;
            this.phoneAndEmail.phone = data.phoneNumber;
            this.$store.commit('formLoading', false);
            this.disableFields = true;
          },
          err => {
            const { message } = err;
            this.$store.commit('message', { type: 'error', text: message });
            this.$store.commit('formLoading', false);
            this.disableFields = true;
            this.showResetPass = false;
            this.emailCode = '';
            this.phoneCode = '';
            this.password = {};
          }
        );
    },
    resetPass() {
      if (this.recaptcha) {
        if (this.$store.state.formLoading) return false;
        // if (this.password.senha !== this.password.confirmaSenha) {
        //   this.$store.commit('message', { type: 'error', text: 'As senhas precisam ser iguais.' });
        //   return false;
        //   // eslint-disable-next-line
        // }
        const { senha: password, confirmaSenha } = this.password;
        if (
          !(
            !!password
            && password === confirmaSenha
            && /[\w]+/.test(password) // has one letter
            && /\d+/.test(password) // has one number
            && /(.*?[\W_].*?){2,}/.test(password) // has two special characters
            && /[a-z]+/.test(password) // has one lowercase letter
            && /[A-Z]+/.test(password) // has one capital letter
            && password.length >= 10
          )
        ) {
          this.$store.commit('message', {
            type: 'error',
            text:
              'A senha deve conter no mínimo 10 caracteres, uma letra maiúscula, uma letra minúscula, um número e dois caracteres especiais.'
          });
          return false;
        }
        this.$store.commit('formLoading', true);
        this.$http
          .post('/users/user-reset-password', {
            document: this.document.replace(/\D/g, ''),
            emailCode: this.emailCode,
            phoneCode: this.phoneCode,
            newPassword: this.password.senha
          })
          .then(
            ({ data }) => {
              const { error } = data;
              if (error) {
                this.disableFields = false;

                this.$store.commit('message', { type: 'error', text: error });
              } else {
                this.$store.commit('message', {
                  type: 'success',
                  text: 'Senha alterada com sucesso.'
                });
                this.close();
                this.$router.push({ name: 'signIn' });
              }
              this.$store.commit('formLoading', false);
            },
            err => {
              // this.$store.commit('message', { type: 'error', text: err.message });
              this.showResetPass = false;
              this.emailCode = '';
              this.phoneCode = '';
              this.password = {};
              this.disableFields = false;
              this.$store.commit('formLoading', false);
              return err;
            }
          );
      }
    },
    checkIfNumCharacterDocument() {
      const documentNumber = (this.document || '').replace(/\D/g, '');
      return documentNumber.length !== 11 && documentNumber.length !== 14;
    }
  },
  beforeMount() {
    if (window.location.origin !== 'https://conta.onlypay.com.br') {
      this.site_key = process.env.VUE_APP_DEV_RECAPTCHA_SITE_KEY;
    } else {
      this.site_key = process.env.VUE_APP_PROD_RECAPTCHA_SITE_KEY;
    }
  },
  mounted() {
    this.$validator.errors.clear();
  }
};
</script>

<template>
  <div class="block">
    <form @submit.prevent="forgotPass" class="block__form" v-if="true">
      <p class="block__form__info">
        Para redefinir sua senha preencha o campo abaixo:
      </p>
      <br />
      <v-textbox
        :disabled="disableFields"
        v-model="document"
        class="block__form__password"
        label="Insira o CPF ou CNPJ"
        name="text"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        placeholder="CPF ou CNPJ"
        required
      />
      <div class="block__form__actions" style="display: flex; flex-direction: column;">
        <v-button
          label="Solicitar códigos"
          class=""
          type="submit"
          :disabled="disableFields"
        />
        <div style="display: flex; justify-content: space-between" >
          <a @click="close" class="block__form__actions__back">
            Não, voltar
          </a>

          <a
              @click="forgotPass"
              class="block__form__actions__back"
              v-if="showResetPass && !phoneField"
            >
              Não recebi meu código
          </a>
        </div>
      </div>
    </form>

    <form @submit.prevent="resetPass" class="block__form" v-if="showResetPass">
      <p v-if="!phoneField && !emailField" class="block__form__info">
        Preencha o campo abaixo com o código recebido:
      </p>
      <br v-if="!phoneField && !emailField" />
      <div style="display: flex; flex-direction: column;">
        <v-textbox
          v-if="!phoneField"
          v-model="phoneCode"
          class="block__form__password"
          :label="cellphoneLabel"
          name="código_celular"
          mask="######"
          :placeholder="`Enviado para: ${phoneAndEmail.phone }`"
          required
        />
      </div>
      <v-button
        v-if="!phoneField"
        label="Ok"
        class="mt-3"
        type="button"
        :disabled="phoneCode.length < 6"
        @click="handlePhoneCodeConfirmation"
      />

      <div class="mb-3" style="display: flex; flex-direction: column;">
        <v-textbox
          v-if="phoneField && !emailField"
          v-model="emailCode"
          class="block__form__password"
          :label="emailLabel"
          name="código email"
          mask="######"
          :placeholder="`Enviado para: ${phoneAndEmail.email }`"
          required
        />

        <v-button
          v-if="phoneField && !emailField"
          label="Ok"
          class="mt-3"
          type="button"
          :disabled="emailCode.length < 6"
          @click="handleEmailCodeConfirmation"
        />
      </div>
      <v-user-password v-if="this.emailField" :data="password" :responsive="false" />

      <div class="mb-4" v-if="this.emailField">
        <vue-recaptcha :sitekey="site_key" @verify="mxVerify"></vue-recaptcha>
      </div>

      <div class="block__form__actions" v-if="this.emailField">
        <v-button label="Atualizar Senha" class="" />
      </div>
    </form>
  </div>
</template>

<style lang="postcss">
.sign-in__form {
  max-height: fit-content !important;
  overflow: scroll;
}
.sign-in__aside {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}
</style>
<style lang="postcss" scoped>
.block {
  padding: 1.5rem 1.5rem 0;
}
.block__form__actions__back {
  margin: 0 0 1rem 0;
  padding: 0.5rem 0 1rem 0;
}
.sub-label {
  display: block;
  font-size: 0.8125rem;
  margin-top: 0.4375rem;
  margin-top: -30px;
  margin-bottom: 35px;
}

</style>
