import { render, staticRenderFns } from "./ChangePass.vue?vue&type=template&id=26c054de&scoped=true"
import script from "./ChangePass.vue?vue&type=script&lang=js"
export * from "./ChangePass.vue?vue&type=script&lang=js"
import style0 from "./ChangePass.vue?vue&type=style&index=0&id=26c054de&lang=postcss"
import style1 from "./ChangePass.vue?vue&type=style&index=1&id=26c054de&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26c054de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26c054de')) {
      api.createRecord('26c054de', component.options)
    } else {
      api.reload('26c054de', component.options)
    }
    module.hot.accept("./ChangePass.vue?vue&type=template&id=26c054de&scoped=true", function () {
      api.rerender('26c054de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ChangePass.vue"
export default component.exports